import "./App.css";

const telegram = "https://t.me/PINGPONGSOL";
const twitter = "https://twitter.com/pingpongonsol";
const birdeye =
  "https://birdeye.so/token/6mzEAt61mCdZqhMXMk6tUVBttwHMq6nSxjsDJRGHTCtx?chain=solana";
const dexTool =
  "https://www.dextools.io/app/en/solana/pair-explorer/BtabxELpTfVvMj3676MzNPh4MpLMHBcWz3d7fYvrgn1R";
const dexToolFrame =
  "https://dexscreener.com/solana/BtabxELpTfVvMj3676MzNPh4MpLMHBcWz3d7fYvrgn1R?embed=1&theme=dark";

function App() {
  return (
    <div id="main">
      <div className="inner">
        <div id="container02" className="container columns">
          <div className="wrapper">
            <div className="inner">
              <div>
                <h1 id="text01">$PINGPONG</h1>
                <p id="text02">
                  GNOPGNIP reversal
                </p>
                <ul id="buttons02" className="buttons">
                  <li>
                    <a href={telegram} className="button n01">
                      <svg>
                        <use xlinkHref="#icon-96fc68818d10374399c6dcf28fe98f75" />
                      </svg>
                      <span className="label">Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a href={twitter} className="button n02">
                      <svg>
                        <use xlinkHref="#icon-31b8880d36499db40d4e47546c4763f3" />
                      </svg>
                      <span className="label">Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a href={dexTool} className="button n03">
                      <svg>
                        <use xlinkHref="#icon-7a66fac84dc5d9fb5fafce395a384d40" />
                      </svg>
                      <span className="label">DexTools</span>
                    </a>
                  </li>
                  <li>
                    <a href={birdeye} className="button n04">
                      <svg>
                        <use xlinkHref="#icon-be848c9691a8e3f7630e3908e426bf9f" />
                      </svg>
                      <span className="label">BirdEye</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <div id="image01" className="image" data-position="center">
                  <span className="frame">
                    <img src="/images/image.jpg" alt="" />
                  </span>
                </div>
              </div>
            </div>
            {dexToolFrame && (
              <div style={{ marginTop: "100px" }}>
                <iframe
                  style={{ width: "100%" }}
                  title="dex"
                  allow="fullscreen"
                  height="800px"
                  src={dexToolFrame}
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
